/* Base Styles and Typography */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: #2563eb;
  --primary-dark: #1d4ed8;
  --primary-light: #dbeafe;
  --secondary-color: #4f46e5;
  --success-color: #10b981;
  --warning-color: #f59e0b;
  --danger-color: #ef4444;
  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --gray-900: #111827;
  --radius-sm: 0.25rem;
  --radius: 0.5rem;
  --radius-md: 0.75rem;
  --radius-lg: 1rem;
  --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-md: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-lg: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --transition: all 0.2s ease-in-out;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--gray-50);
  color: var(--gray-800);
}

.App {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem;
}

.disclaimer {
  text-align: center;
  margin: 2rem auto 1.5rem;
  max-width: 800px;
  color: var(--gray-500);
  font-size: 0.8rem;
  line-height: 1.4;
}

.container {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
}

@media (max-width: 992px) {
  .container {
    flex-direction: column;
  }
}

.panel {
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow);
  padding: 1.25rem;
  flex: 1;
  transition: var(--transition);
  border: 1px solid var(--gray-200);
}

.left-panel {
  background-color: white;
  flex: 0.4;
}

.right-panel {
  background-color: white;
  flex: 0.6;
}

.panel h2 {
  margin-top: 0;
  color: var(--gray-800);
  font-size: 1.35rem;
  font-weight: 600;
  margin-bottom: 1.25rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--gray-200);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panel h3 {
  color: var(--gray-700);
  font-size: 1.15rem;
  font-weight: 600;
  margin: 1.5rem 0 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--gray-200);
}

/* Left Panel - Spending Inputs */
.spending-inputs {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-bottom: 1.5rem;
}

.input-group {
  display: flex;
  flex-direction: column;
}

.input-group label {
  margin-bottom: 0.4rem;
  font-weight: 500;
  color: var(--gray-700);
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: var(--radius);
  box-shadow: var(--shadow-sm);
  transition: var(--transition);
}

.input-wrapper:focus-within {
  box-shadow: 0 0 0 2px var(--primary-light);
}

.input-wrapper.small {
  width: 140px;
}

.currency-symbol {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  padding: 0.75rem 0.875rem;
  background-color: var(--gray-100);
  border: 1px solid var(--gray-300);
  border-right: none;
  border-radius: var(--radius) 0 0 var(--radius);
  font-size: 0.95rem;
  font-weight: 500;
  color: var(--gray-700);
}

.input-suffix {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.875rem;
  background-color: var(--gray-100);
  border: 1px solid var(--gray-300);
  border-left: none;
  border-radius: 0 var(--radius) var(--radius) 0;
  font-size: 0.95rem;
  font-weight: 500;
  color: var(--gray-700);
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
  flex: 1;
  padding: 0.75rem 0.875rem;
  font-size: 0.95rem;
  font-weight: 500;
  border: 1px solid var(--gray-300);
  background-color: white;
  outline: none;
  transition: var(--transition);
  color: var(--gray-800);
}

.input-wrapper.small input[type='number'] {
  border-radius: 0;
}

input[type='number']:focus {
  border-color: var(--primary-color);
}

.spending-summary {
  margin-top: 1.5rem;
  padding: 1rem 1.25rem;
  background-color: var(--primary-light);
  border-radius: var(--radius);
  text-align: center;
  font-weight: 500;
  color: var(--primary-dark);
  border: 1px solid rgba(37, 99, 235, 0.2);
}

/* Right Panel - Optimization Controls */
.optimization-controls {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.mile-value-info {
  font-size: 0.875rem;
  color: var(--gray-600);
  margin-bottom: 1rem;
  background-color: var(--gray-50);
  padding: 0.5rem 0.75rem;
  border-radius: var(--radius);
  border: 1px solid var(--gray-200);
}

.control-buttons {
  display: flex;
  align-items: center;
}

.settings-button, .dev-settings-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.375rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  margin-left: 0.5rem;
}

.settings-button:hover, .dev-settings-button:hover {
  background-color: var(--gray-100);
  color: var(--primary-color);
}

.settings-icon, .dev-settings-icon {
  font-size: 1.3rem;
  color: var(--gray-600);
}

/* Rewards Summary Section */
.rewards-summary {
  background-color: var(--primary-light);
  padding: 1rem;
  border-radius: var(--radius);
  margin-bottom: 1.25rem;
  border: 1px solid rgba(37, 99, 235, 0.15);
}

.rewards-summary > p {
  margin-top: 0;
  font-weight: 600;
  color: var(--gray-700);
  margin-bottom: 1rem;
  font-size: 0.95rem;
}

.rewards-grid {
  display: flex;
  gap: 1rem;
}

.reward-column {
  flex: 1;
  background-color: white;
  border-radius: var(--radius);
  padding: 1rem;
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--gray-200);
}

.reward-column.current {
  background-color: white;
  box-shadow: var(--shadow);
  border: 1px solid rgba(37, 99, 235, 0.15);
}

.reward-column h4 {
  margin-top: 0;
  margin-bottom: 0.75rem;
  color: var(--gray-700);
  font-size: 0.9rem;
  font-weight: 600;
  text-align: center;
}

.reward-value {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.625rem 0;
  font-size: 0.9rem;
  justify-content: center;
}

.reward-value.total-value {
  margin-top: 0.875rem;
  border-top: 1px solid var(--gray-200);
  padding-top: 0.875rem;
  font-weight: 600;
  color: var(--primary-color);
}

.reward-icon {
  font-size: 1rem;
}

/* Optimal Card Strategy Section */
.optimal-strategy {
  margin-bottom: 2rem;
}

.strategy-summary {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.card-strategy {
  background-color: white;
  border-radius: var(--radius);
  overflow: hidden;
  box-shadow: var(--shadow);
  border: 1px solid var(--gray-200);
  transition: var(--transition);
}

.card-strategy:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.card-strategy-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.25rem;
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  color: white;
}

.card-strategy-name {
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.01em;
  margin-right: auto;
  margin-left: 0.75rem;
}

.card-details-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--gray-100);
  border-top: 1px solid var(--gray-200);
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-size: 0.8rem;
}

.card-details-toggle:hover {
  background-color: var(--gray-200);
}

.toggle-content {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.info-toggle-icon {
  font-size: 1rem;
  color: var(--primary-color);
}

.chevron-icon {
  font-size: 1rem;
  color: var(--gray-600);
  transition: transform 0.3s ease;
}

.card-details-content {
  border-top: 1px solid var(--gray-200);
  animation: expandDown 0.3s ease-out;
  overflow: hidden;
}

@keyframes expandDown {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 2000px;
    opacity: 1;
  }
}

.bank-logo {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
  border-radius: 50%;
  font-weight: 600;
  font-size: 0.9rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.category-list {
  padding: 0.625rem 1rem;
}

.category-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid var(--gray-200);
}

.category-item:last-child {
  border-bottom: none;
}

.category-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.category-name {
  font-weight: 600;
  color: var(--gray-700);
  font-size: 0.9rem;
}

.category-spend {
  font-size: 0.825rem;
  color: var(--gray-500);
}

.category-spend span {
  color: var(--primary-color);
  font-weight: 600;
}

.category-reward {
  font-weight: 600;
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 0.9rem;
}

.card-strategy-total {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.25rem;
  background-color: var(--gray-50);
  font-weight: 600;
  border-top: 1px solid var(--gray-200);
}

.card-limit-warning {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 0.75rem 1.25rem;
  background-color: var(--warning-color);
  background-color: rgba(245, 158, 11, 0.1);
  font-size: 0.875rem;
  color: var(--warning-color);
  border-top: 1px solid rgba(245, 158, 11, 0.2);
}

.warning-icon {
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

.card-total-miles {
  color: var(--primary-color);
}

.card-total-cashback {
  color: var(--success-color);
}

/* Card Details Section */
.card-details {
  margin-top: 2rem;
}

.card-recommendation {
  border: 1px solid var(--gray-200);
  border-radius: var(--radius);
  overflow: hidden;
  margin-bottom: 1.5rem;
  transition: var(--transition);
  background-color: white;
  box-shadow: var(--shadow);
}

.card-recommendation:hover {
  transform: translateY(-3px);
  box-shadow: var(--shadow-md);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem;
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  color: white;
  cursor: pointer;
}

.card-header-main {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.card-header h3 {
  margin: 0;
  font-size: 1.15rem;
  border-bottom: none;
  padding-bottom: 0;
  color: white;
  font-weight: 600;
}

.dropdown-icon {
  font-size: 0.9rem;
  transition: var(--transition);
}

.card-details-content {
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card-category {
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.3rem 0.6rem;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: var(--radius-sm);
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.card-rewards {
  padding: 1.25rem;
  border-bottom: 1px solid var(--gray-200);
  background-color: var(--gray-50);
}

.bank-name {
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: var(--gray-600);
  font-size: 0.925rem;
  font-weight: 500;
}

.card-rewards p {
  margin: 0.625rem 0;
  display: flex;
  justify-content: space-between;
  font-size: 0.925rem;
}

.card-best-for, 
.card-benefits, 
.card-exclusions {
  padding: 1.25rem;
  border-bottom: 1px solid var(--gray-200);
}

.card-best-for p, 
.card-benefits p, 
.card-exclusions p {
  margin-top: 0;
  margin-bottom: 0.75rem;
  font-weight: 600;
  color: var(--gray-700);
  font-size: 0.95rem;
}

.card-best-for ul {
  list-style-type: none;
  padding: 0;
  margin: 0.75rem 0 0 0;
}

.card-best-for li {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
}

.card-benefits pre, 
.card-exclusions pre {
  margin: 0.75rem 0 0 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  white-space: pre-wrap;
  font-size: 0.875rem;
  line-height: 1.6;
  color: var(--gray-700);
}

.card-link {
  padding: 1.25rem;
  text-align: center;
  background-color: white;
}

.card-link a {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  color: white;
  text-decoration: none;
  border-radius: var(--radius);
  font-weight: 500;
  transition: var(--transition);
  font-size: 0.9rem;
  box-shadow: var(--shadow-sm);
}

.card-link a:hover {
  transform: translateY(-1px);
  box-shadow: var(--shadow);
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.modal-content {
  background-color: white;
  border-radius: var(--radius-lg);
  width: 90%;
  max-width: 500px;
  box-shadow: var(--shadow-lg);
  overflow: hidden;
  animation: modalFadeIn 0.3s ease-out;
  border: 1px solid var(--gray-200);
}

.modal-content.dev-settings {
  max-width: 600px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
}

.dev-settings .modal-body {
  overflow-y: auto;
}

.card-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 1rem;
  max-height: 50vh;
  overflow-y: auto;
  padding: 0.5rem;
  border: 1px solid var(--gray-200);
  border-radius: var(--radius);
  background-color: var(--gray-50);
}

.settings-description {
  font-size: 0.9rem;
  color: var(--gray-600);
  margin-bottom: 0.5rem;
}

.card-toggle {
  padding: 0.75rem;
  border-radius: var(--radius);
  background-color: white;
  border: 1px solid var(--gray-200);
  transition: var(--transition);
}

.card-toggle:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow);
}

.toggle-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggle-container input {
  margin-right: 1rem;
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
}

.card-toggle-info {
  display: flex;
  flex-direction: column;
}

.card-toggle-name {
  font-weight: 600;
  font-size: 0.95rem;
  color: var(--gray-800);
}

.card-toggle-bank {
  font-size: 0.8rem;
  color: var(--gray-500);
  margin-top: 0.25rem;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid var(--gray-200);
  background-color: var(--gray-50);
}

.modal-header h3 {
  margin: 0;
  font-size: 1.25rem;
  color: var(--gray-800);
  border-bottom: none;
  padding-bottom: 0;
  font-weight: 600;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--gray-500);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  transition: var(--transition);
}

.close-button:hover {
  background-color: var(--gray-200);
  color: var(--gray-700);
}

.modal-body {
  padding: 1.5rem;
}

.settings-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: 600;
  color: var(--gray-700);
  font-size: 0.95rem;
}

.form-help {
  font-size: 0.825rem;
  color: var(--gray-500);
  margin-top: 0.375rem;
  line-height: 1.4;
}

.modal-footer {
  padding: 1.25rem 1.5rem;
  border-top: 1px solid var(--gray-200);
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  background-color: var(--gray-50);
}

.button {
  padding: 0.625rem 1.25rem;
  border-radius: var(--radius);
  font-weight: 500;
  cursor: pointer;
  border: none;
  font-size: 0.9rem;
  transition: var(--transition);
  box-shadow: var(--shadow-sm);
}

.button.primary {
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  color: white;
}

.button.primary:hover {
  transform: translateY(-1px);
  box-shadow: var(--shadow);
}

.button.secondary {
  background-color: white;
  color: var(--gray-700);
  border: 1px solid var(--gray-300);
}

.button.secondary:hover {
  background-color: var(--gray-100);
  border-color: var(--gray-400);
}